import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons';
import {
  Alert,
  Descriptions,
  Skeleton,
  Table,
  TableProps,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Address, core, errors } from 'accumulate.js';
import {
  MessageRecord,
  RecordType,
  SignatureSetRecord,
} from 'accumulate.js/lib/api_v3';
import {
  Account,
  AccountType,
  DelegatedSignature,
  KeySpec,
  SignatureType,
} from 'accumulate.js/lib/core';
import {
  MessageType,
  SignatureMessage,
  TransactionMessage,
} from 'accumulate.js/lib/messaging';

import {
  MsgRecord,
  SigMessage,
  SigRecord,
  isRecordOf,
} from '../../utils/types';
import { KeyPage } from '../account/KeyPage';
import { AccTitle } from '../common/AccTitle';
import { EnumValue } from '../common/EnumValue';
import { InfoTable } from '../common/InfoTable';
import { Link } from '../common/Link';
import { queryEffect } from '../common/query';
import { Status } from '../message/Status';
import Error404 from './Error404';

const { Title } = Typography;

export function SigningDetails() {
  const [error, setError] = useState(null);
  const [record, setRecord] = useState<MessageRecord>(null);

  const params = useParams() as {
    hash?: string;
  };

  document.title = `${params.hash} | Accumulate Explorer`;
  if (!params.hash) {
    throw new Error('Routing error, missing params');
  }

  queryEffect(`${params.hash}@unknown`, { queryType: 'default' }).then((r) => {
    if (r.recordType === RecordType.Error) {
      setError(r.value);
    } else if (r.recordType !== RecordType.Message) {
      setError(`${params.hash} is not a transaction`);
    } else {
      setError(null);
      setRecord(r);
    }
    return r;
  });

  if (error) {
    if (
      error instanceof errors.Error &&
      error.code === errors.Status.NotFound
    ) {
      return <Error404 />;
    }
    return (
      <>
        <Title>Signing Details</Title>
        <Alert message={`${error}`} type="error" showIcon />
      </>
    );
  }

  if (!record) {
    return (
      <>
        <Title>Signing Details</Title>
        <div className="skeleton-holder">
          <Skeleton active />
        </div>
      </>
    );
  }

  const type = isRecordOf(record, TransactionMessage)
    ? 'Transaction'
    : 'Message';
  const sigsets = record.signatures.records;

  return (
    <>
      <AccTitle title={`${type} Signing Details`} url={record.id} />
      <div style={{ marginBottom: '20px' }}>
        <Status record={record} />
      </div>

      {sigsets.map((x, i) => (
        <>
          <SignatureSet key={i} value={x} />
        </>
      ))}
    </>
  );
}

function SignatureSet({ value }: { value: SignatureSetRecord }) {
  const { account } = value;
  if (!(account instanceof core.KeyPage)) {
    return (
      <>
        <Title level={4}>
          <For account={account} />
        </Title>
        <InfoTable>
          {value.signatures.records.map((x, i) => (
            <Descriptions.Item
              key={i}
              label={<EnumValue type={MessageType} value={x.message.type} />}
            >
              <Link to={x.id}>{`${x.id}`}</Link>
            </Descriptions.Item>
          ))}
        </InfoTable>
      </>
    );
  }

  const otherSignatures: SigRecord[] = [];
  const activeSignatures = new Map<KeySpec, SigRecord<core.UserSignature>>();
  const historicalSignatures = new Map<
    KeySpec,
    SigRecord<core.UserSignature>[]
  >();
  for (const r of value.signatures.records) {
    if (!isRecordOf(r, SignatureMessage)) continue;
    const {
      message: { signature },
    } = r;

    let keySpec: KeySpec;
    if (signature instanceof core.AuthoritySignature) {
      keySpec = account.keys.find(
        (x) => x.delegate && signature.authority.equals(x.delegate),
      );
    }

    let inner = signature;
    while (inner instanceof DelegatedSignature) {
      inner = inner.signature;
    }

    if ('publicKey' in inner) {
      try {
        const keyType =
          inner.type === SignatureType.TypedData
            ? SignatureType.ETH
            : inner.type;
        const addr = Address.fromKey(keyType, inner.publicKey);
        keySpec = account.keys.find(
          (x) =>
            x.publicKeyHash &&
            bin2hex(x.publicKeyHash) === bin2hex(addr.publicKeyHash),
        );
      } catch (error) {
        console.warn('Failed to process signature:', error);
      }
    }

    if (!keySpec) {
      otherSignatures.push(r);
      continue;
    }

    if (r.historical) {
      const sigs = historicalSignatures.get(keySpec) || [];
      historicalSignatures.set(keySpec, sigs);
      sigs.push(r as SigRecord<core.UserSignature>);
    } else {
      activeSignatures.set(keySpec, r as SigRecord<core.UserSignature>);
    }
  }

  const columns: TableProps<core.KeySpec>['columns'] = [
    {
      title: 'Entry',
      render(r: core.KeySpec) {
        return <KeyPage.Entry entry={r} prefer="delegate" />;
      },
    },
    {
      title: 'Signature',
      render(r: core.KeySpec) {
        const active = activeSignatures.get(r);
        const historical = historicalSignatures.get(r);
        if (!active && !historical?.length) {
          return (
            <Tag color="gray">
              <CloseOutlined />
            </Tag>
          );
        }
        return (
          <>
            {active && (
              <div>
                <Tag color="green">
                  <CheckOutlined />
                </Tag>
                <Link to={active.id}>{`${active.id}`}</Link>
              </div>
            )}
            {historical?.map((x, i) => (
              <div
                key={`${x.id}`}
                style={{ marginTop: active || i > 0 ? '10px' : null }}
              >
                <Tooltip title="This is a historical signature. Either: the signature threshold was reached, the signature was superseded by a newer one, or a change to the signer invalidated the signature.">
                  {active ? (
                    <Tag color="yellow">
                      <MinusOutlined />
                    </Tag>
                  ) : (
                    <Tag color="orange">
                      <CloseOutlined />
                    </Tag>
                  )}
                  <Link to={x.id}>{`${x.id}`}</Link>
                </Tooltip>
              </div>
            ))}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Title level={4}>
        <For account={account} />{' '}
        {account.acceptThreshold > 1 && (
          <Tag>{`${activeSignatures.size} of ${account.acceptThreshold}`}</Tag>
        )}
      </Title>
      <Table
        style={{ marginBottom: '20px' }}
        dataSource={account.keys}
        columns={columns}
        rowKey={(r) =>
          r.publicKeyHash ? bin2hex(r.publicKeyHash) : `${r.delegate}`
        }
        pagination={false}
      />
      <InfoTable>
        {otherSignatures.map((x, i) => (
          <Descriptions.Item
            key={i}
            label={<EnumValue type={MessageType} value={x.message.type} />}
          >
            <Link to={x.id}>{`${x.id}`}</Link>
          </Descriptions.Item>
        ))}
      </InfoTable>
    </>
  );
}

function For({ account }: { account: Account }) {
  switch (account.type) {
    case AccountType.KeyPage:
      return (
        <>
          Signatures for page <Link to={account.url}>{`${account.url}`}</Link>
        </>
      );

    case AccountType.KeyBook:
      return (
        <>
          Signatures for book <Link to={account.url}>{`${account.url}`}</Link>
        </>
      );

    case AccountType.LiteIdentity:
      return (
        <>
          Signatures for lite id{' '}
          <Link to={account.url}>{`${account.url}`}</Link>
        </>
      );

    default:
      return (
        <>
          Signatures for account{' '}
          <Link to={account.url}>{`${account.url}`}</Link>
        </>
      );
  }
}

function bin2hex(v: Uint8Array) {
  return Buffer.from(v).toString('hex');
}
